import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { axios, setAuthorization } from '../utils/axios';

import { dayjs } from 'utils/dayjs';

const store = (set, get) => ({
  user: null,
  isUpdateUserSettingsLoading: false,
  appView: '',
  session: null,
  isAuthenticated: false,
  isImpersonating: false,

  setUser: (userData) => set({ user: userData, appView: userData?.app_view }),
  setAppView: (appView) => set({ appView }),

  updateUser: (data) => {
    const currentUser = get().user;
    set({ user: { ...currentUser, ...data } });
    set({ appView: data.app_view });
  },

  updateUserSettings: async (data) => {
    const { updateUser } = get();
    set({ isUpdateUserSettingsLoading: true });

    try {
      const response = await axios.patch(`/settings/${get().user.id}/`, data);
      if (response.status === 200) {
        updateUser(response.data);
      }
    } catch (error) {
      console.log('updateUserSettings error', error);
    } finally {
      set({ isUpdateUserSettingsLoading: false });
    }
  },

  refreshUser: async () => {
    try {
      const response = await axios.get(`/user/${get().user.id}/`);
      get().updateUser(response.data);
      return response.data;
    } catch (error) {
      console.log('refreshUser error', error);
      throw error;
    }
  },

  setIsUpdateUserSettingsLoading: (isLoading) => {
    set({ isUpdateUserSettingsLoading: isLoading });
  },

  setSessionData: (sessionData, status) => {
    set({
      session: sessionData,
      isAuthenticated: status ? status === 'authenticated' : false,
    });
    if (sessionData && sessionData?.user) {
      setAuthorization(sessionData.user.key);
    }
  },

  getConnectButtonState: (business) => {
    const isReserved = dayjs().isBefore(business.premium_only_until);
    const hasCompleteProfile = get().user?.onboarding_has_complete_profile;
    const canStartConversations = get().user?.person?.can_start_conversations;
    const hasRejiggPremium = get().user?.person?.has_rejigg_premium;
    const isHighQualitySearcher = get().user?.person?.is_high_quality_searcher;

    if (isReserved && !hasRejiggPremium && !isHighQualitySearcher) {
      return 'RESERVED';
    }

    if (!hasCompleteProfile) {
      return 'INCOMPLETE_PROFILE';
    }

    if (!canStartConversations) {
      if (hasRejiggPremium) {
        return 'TOO_MANY_CONVERSATIONS';
      } else {
        return 'SUBSCRIBE_OR_REVEAL';
      }
    }

    return 'SEND_PITCH';
  },

  setIsImpersonating(isImpersonating) {
    set({ isImpersonating });
  },

  stopImpersonation: async () => {
    try {
      await axios.delete('/impersonate/stop/');
      window.location.reload();
    } catch (error) {
      window.location.reload();
      alert('Failed to stop impersonation');
    }
  },

  startImpersonation: async (impersonateId, router) => {
    try {
      await axios.post(`/impersonate/start/`, { user: impersonateId });
      await router.push('/');
      await router.reload();
    } catch (error) {
      alert('Failed to start impersonation. ' + error?.response?.data?.detail);
    }
  },

  checkImpersonation: (session) => {
    const user = get().user;
    if (session?.user?.id && user?.id && session.user.id !== user?.id) {
      set({ isImpersonating: true });
    } else {
      set({ isImpersonating: false });
    }
  },
});

const useUserStore = create(
  devtools(store, {
    name: 'userStore',
    enabled: process.env.NODE_ENV === 'development',
  }),
);

export default useUserStore;
