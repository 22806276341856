import { createContext, useState, useEffect, useContext } from 'react';
import { axios } from 'utils/axios';

import { Box, Typography } from '@mui/material';
import Link from 'next/link';

import useUserStore from 'stores/userStore';
import { useRouter } from 'next/router';

import { get } from 'lodash';

const WelcomeLetterContent = ({ user }) => {
  return (
    <Box
      sx={{
        maxHeight: 'calc(100dvh - 300px)',
        overflowY: 'auto',
        px: 3,
        mx: -3,
        textAlign: 'left',
      }}
    >
      <Typography>
        Hi {user.person.first_name},
        <br />
        <br />
        Welcome to Rejigg! We’re excited to help you find your next acquisition opportunity. Before you get started, you
        should know:
      </Typography>
      <ul>
        <li>
          <b>95%+ of our leads are proprietary</b>, off-market, and unique to Rejigg.
        </li>
        <li>
          <b> Directly connect with 3 owners</b> on the platform without having to upgrade your account.
        </li>
      </ul>
      <Typography>
        Subscribe to{' '}
        <Link href="https://app.rejigg.com/subscribe-to-rejigg" target="_blank" rel="noreferrer">
          Rejigg Full Access
        </Link>{' '}
        to get:
      </Typography>
      <ul>
        <li>
          {' '}
          <b>Prioritized access</b> to owners{' '}
        </li>
        <li>
          {' '}
          <b>Lower success fee</b> (1.5% vs 2.5%) when you close{' '}
        </li>
        <li>
          {' '}
          <b>Full unredacted info</b> about our listings{' '}
        </li>
        <li>
          {' '}
          <b>Unlimited connects</b> with owners{' '}
        </li>
      </ul>

      <Typography>
        Happy searching! <br />- Barrett, Rejigg CEO
      </Typography>
    </Box>
  );
};

const OwnerWelcomeLetterContent = ({ user }) => {
  return (
    <Box
      sx={{
        maxHeight: 'calc(100dvh - 300px)',
        overflowY: 'auto',
        px: 3,
        mx: -3,
        textAlign: 'left',
      }}
    >
      <Typography>
        Hi {user.person.first_name},
        <br />
        <br />
        Welcome to Rejigg! We're excited to help you find a buyer for your business. Before you get started, you should
        know:
      </Typography>
      <ul>
        <li>We don't charge owners anything to list their business on Rejigg or engage with our pool of buyers.</li>
        <li>
          On Rejigg, you'll find a mix of strategic acquirers, institutional investors and individual owner/operators.
        </li>
        <li>
          Once your listing is live and you start to get requests, we'll check in periodically to see how things are
          going. We have a deep rolodex of advisors, lawyers and accountants we're happy to share if desired as you get
          deeper into the process.
        </li>
      </ul>
      <b>Please note: </b>If your business does less than $500k in annual revenue, we're probably not the right platform
      for you given our buyer pool. <br />
      <br />
      <Typography>
        Cheers, <br />
        Barrett, Rejigg CEO
      </Typography>
    </Box>
  );
};

export const OnboardingContext = createContext();

/**
 * Keeps track of visible OnboardingSteps and the User's onboarding progress
 *
 * @param {*} param0
 * @returns OnboardingContext.Provider
 */
export const OnboardingProvider = ({ children }) => {
  const user = useUserStore((state) => state.user);
  const router = useRouter();
  const [data, setData] = useState({});
  const [steps, setSteps] = useState({
    searcher_has_dismissed_welcome_letter: {
      audience: 'SEARCHER',
      title: 'Welcome to Rejigg!',
      content: <WelcomeLetterContent user={user} />,
      isVisible: false,
    },
    searcher_has_used_filters: {
      audience: 'SEARCHER',
      title: 'Use filters to find the right business!',
      isVisible: false,
    },
    searcher_has_made_pitch: {
      audience: 'SEARCHER',
      title: 'Message the Owner',
      content: 'Direct message the owner and schedule calls',
      isVisible: false,
    },
    searcher_has_complete_profile: {
      audience: 'SEARCHER',
      title: 'Complete your Profile!',
      content: 'Stand out to Owners by telling them about your background and search',
      isVisible: false,
    },
    owner_has_dismissed_welcome_letter: {
      audience: 'OWNER',
      title: 'Welcome to Rejigg!',
      content: <OwnerWelcomeLetterContent user={user} />,
      isVisible: false,
    },
    owner_has_complete_profile: {
      audience: 'OWNER',
      title: 'Tell Us about Yourself',
      content: 'Update your profile to help potential buyers get to know you',
      isVisible: false,
    },
    owner_has_updated_financials: {
      audience: 'OWNER',
      title: 'Update your financial history',
      content: 'We use this information to help you find the right buyer',
      isVisible: false,
    },
    general_has_scheduled_meeting: {
      audience: 'ALL',
      title: 'Schedule Calls from the App!',
      content: 'Use the calls button to schedule calls instantly',
      isVisible: false,
    },
    general_has_seen_help_center: {
      audience: 'ALL',
      title: "We're here to help",
      content: 'Contact support or access FAQs from the Help Center',
      isVisible: false,
    },
  });
  const [nextStep, setNextStep] = useState('');

  function register(step) {
    if (!steps[step]) {
      console.warn(`Step ${step} not found.`);
    }

    if (get(steps, `${step}.audience`) === 'ALL' || get(steps, `${step}.audience`) === user?.app_view) {
      setSteps((prevSteps) => ({
        ...prevSteps,
        [step]: {
          ...prevSteps[step],
          isVisible: true,
        },
      }));
    }
  }

  function unregister(step) {
    setSteps((prevSteps) => ({
      ...prevSteps,
      [step]: {
        ...prevSteps[step],
        isVisible: false,
      },
    }));
  }

  function handleStepComplete(name) {
    axios
      .patch(`/onboarding/${data.id}/`, {
        [name]: true,
      })
      .then((res) => {
        setData(res.data);
      });
  }

  const findNextStep = () => {
    for (const [key, value] of Object.entries(steps)) {
      if (value.isVisible && data[key] === false) {
        setNextStep(key);
        return;
      }
    }

    setNextStep('');
  };

  const start = () => {
    axios
      .get(`/onboarding/`)
      .then((res) => {
        setData(res?.data.results[0]);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    findNextStep();
  }, [JSON.stringify(data), steps]);

  useEffect(() => {
    user?.id && start();
  }, []);

  return (
    <OnboardingContext.Provider
      value={{
        steps,
        register,
        unregister,
        handleStepComplete,

        nextStep,
      }}
    >
      {children}
    </OnboardingContext.Provider>
  );
};

export const useOnboardingContext = () => {
  return useContext(OnboardingContext);
};
