import { createContext, useContext, useEffect, useState } from 'react';
import useUserStore from 'stores/userStore';
import ReconnectingWebSocket from 'reconnecting-websocket';

const RealTimeContext = createContext();

const RealTimeProvider = ({ children }) => {
  const user = useUserStore((state) => state.user);
  const [socket, setSocket] = useState(null);

  const updateStore = async (event) => {
    console.log(event);
  };

  useEffect(() => {
    if (!user) return;

    if (!user.flags.includes('websockets preview')) return;

    const WS_HOST = process.env.NEXT_PUBLIC_WS_HOST || 'localhost:8000';
    const WS_PROTOCOL = process.env.NEXT_PUBLIC_WS_PROTOCOL || 'ws';

    const options = {
      connectionTimeout: 6000,
      maxRetries: 10,
    };
    const newChatSocket = new ReconnectingWebSocket(
      `${WS_PROTOCOL}://${WS_HOST}/ws/${user.id}/?key=${user.key}`,
      [],
      options,
    );
    setSocket(newChatSocket);

    newChatSocket.onopen = () => {
      console.log('[rws] connected');
    };

    newChatSocket.onmessage = (e) => {
      if (!e?.data) return;

      const data = JSON.parse(e?.data);

      updateStore(data);
    };

    newChatSocket.onclose = () => {
      console.log('[rws] disconnected');
    };

    return () => {
      console.log('[rws] disconnecting');
      newChatSocket.close();
    };
  }, []);

  return <RealTimeContext.Provider value={socket}>{children}</RealTimeContext.Provider>;
};

const useRealTimeContext = () => useContext(RealTimeContext);
export { RealTimeProvider, useRealTimeContext };
